import React from "react"
// import { Link } from "gatsby"
// import { StaticImage } from "gatsby-plugin-image"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { Aside } from "components/blog/aside"
import { Breadcrumb } from "components/breadcrumb"
import { DefaultHeader } from "components/headers/default-header"

const CanIDistributeAmazonGift = ({ location }) => {
  const title = "我可以将亚马逊礼券设置为即时赢取的奖品吗？"
  const list = [
    { title: "常见问题", link: "/qa" },
    { title: title, link: location.pathname },
  ]

  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle={`FAQ | ${title}`}
          pagedesc="PARKLoT活动可以将亚马逊礼品作为礼物分发。 其他独特的优惠券和QUO卡支付也可以分发。"
          pagepath={location.pathname}
        />
        <Breadcrumb title="常见问题" items={list} />
        <div className="qa-page l-content l-container">
          <main className="l-main-content">
            <h1 className="qa-page__title">
              我可以分发亚马逊礼券作为活动奖品吗？
            </h1>
            <p className="qa-page__answer">是的，这是有可能的。</p>
            <p className="qa-page__text">
              亚马逊礼券的发放可以使用PARKLoT功能自动进行。
              该系统还可以将抽奖过程自动化，所以完全没有麻烦。
            </p>

            <div className="qa-page__detail-container">
              {/* <h2 className="qa-page__h2">  
              【法人向け】 Amazonギフト券をキャンペーンの景品として利用できない商品・サービスは下記です。
            </h2> */}
              <div>
                <h2 className="qa-page__h2">
                  [公司] 亚马逊礼券不能作为活动奖品的产品和服务包括
                </h2>
                <div className="qa-page__text">
                  <ol className="qa-page__ol">
                    <li>烟草</li>
                    <li>色情和成人产品和服务</li>
                    <li>逃避法律的药物（如硝酸戊酯，MiPT）</li>
                    <li>约会服务*1</li>
                    <li>赌博*2</li>
                    <li>网上拍卖</li>
                    <li>消费信贷*3</li>
                    <li>每日交易和折扣服务行业（如联合购买优惠券服务）</li>
                    <li>直播服务</li>
                    <li>虚拟货币</li>
                    <li>电话营销</li>
                    <li>禁运货物的贸易或与禁运国家的贸易</li>
                    <li>枪械</li>
                    <li>宗教和政治活动</li>
                    <li>亚马逊认为非法或不适当的产品和服务</li>
                  </ol>
                </div>
                <h3 className="qa-page__h3">
                  欲了解更多信息，请参阅关于使用企业亚马逊礼券的指南。
                </h3>
                <p className="qa-page__text">
                  更多信息可以在亚马逊网站上找到。
                </p>
                <p className="qa-page__text">
                  [相关链接]
                  <a
                    href="https://www.amazon.co.jp/b?ie=UTF8&node=4731316051"
                    class="information__link"
                    target="_blank"
                    rel="noreferrer"
                  >
                    亚马逊礼券不能使用的产品和服务（截止到2019年6月）
                  </a>
                </p>
              </div>
              <div>
                <h2 className="qa-page__h2">分发其他礼品券</h2>
                <p className="qa-page__text">
                  结合PARKLoT服务的亚马逊礼券的自动分发可作为一个选项。
                </p>
                <p className="qa-page__text">
                  除亚马逊礼品券外，各种礼品代码、优惠券和代金券的分发也可以通过Twitter直接传递给用户。
                </p>
                <p className="qa-page__text">
                  PARKLoT可以用来自动完成分发亚马逊礼券的繁琐任务。
                </p>
                <p className="qa-page__text">欲了解更多信息，请联系。</p>
              </div>
            </div>
          </main>
          <Aside />
        </div>
      </Layout>
    </>
  )
}

export default CanIDistributeAmazonGift
